import { AnnotatedFx } from "../../../../content/wiskunde/1g_fx/intro/drawings";
import Onderhandeling from "../../../../content/wiskunde/1g_fx/intro/onderhandeling";
import DirkFestival from "../../../../content/wiskunde/1g_fx/intro/dirk_festival";
import MariaPralines from "../../../../content/wiskunde/1g_fx/intro/maria_pralines";
import SoepEten from "../../../../content/wiskunde/1g_fx/intro/soep_eten";
import { WatIsMEnQ, Herken1GFx } from "../../../../content/wiskunde/1g_fx/intro/exercises";
import { Herken1GFxTexts, MariaSegway } from "../../../../content/wiskunde/1g_fx/intro/exercises";
import * as React from 'react';
export default {
  AnnotatedFx,
  Onderhandeling,
  DirkFestival,
  MariaPralines,
  SoepEten,
  WatIsMEnQ,
  Herken1GFx,
  Herken1GFxTexts,
  MariaSegway,
  React
};