import { AnnotArrow } from "components/drawings/annotArrow";
import { OneDotGraph, DotsGraph, Graph1G } from "../../../../content/wiskunde/1g_fx/grafiek/drawings";
import { InteractGraph1G } from "../../../../content/wiskunde/1g_fx/grafiek/drawings";
import { InvloedM, InvloedQ } from "../../../../content/wiskunde/1g_fx/grafiek/exercises";
import * as React from 'react';
export default {
  AnnotArrow,
  OneDotGraph,
  DotsGraph,
  Graph1G,
  InteractGraph1G,
  InvloedM,
  InvloedQ,
  React
};