import { FxValueSlider, InteractiveTekenschema, Tekenschema1G } from "../../../../content/wiskunde/1g_fx/tekenschema/drawings";
import { NulpuntGraph1G } from "../../../../content/wiskunde/1g_fx/tekenschema/drawings";
import { FxTable } from "../../../../content/wiskunde/1g_fx/tekenschema/drawings";
import { Katex } from 'components/katex';
import { FxTekenschema } from "../../../../content/wiskunde/1g_fx/tekenschema/exercises";
import * as React from 'react';
export default {
  FxValueSlider,
  InteractiveTekenschema,
  Tekenschema1G,
  NulpuntGraph1G,
  FxTable,
  Katex,
  FxTekenschema,
  React
};